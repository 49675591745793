let md5 = require('md5');
let tokenRequested = false;

interface PromisesObject {
  [key: string]: Promise<any>;
}

interface ResponsesObject {
  [key: string]: Object;
}


let runningQueries: PromisesObject = {};
let responses: ResponsesObject = {};

// tokenType 0 = API, 1 = USER, 2 = USER if possible (API otherwise)
const Api: (query: string, postParams?: object, tokenType?: number, noCache?: boolean | undefined) => Promise<any> = async (query: string, postParams?: object, tokenType?: number, noCache?: boolean) => {
  if (process.env.REACT_APP_API_URL) {
    let requestUrl = process.env.REACT_APP_API_URL.toString();

    let tokenExpires = 0;
    let apiToken = sessionStorage.getItem('apiToken');
    let userToken = sessionStorage.getItem('userToken');

    if (userToken && userToken === 'undefined') {
      userToken = null;
    }

    if (apiToken && apiToken !== 'undefined') {
      tokenExpires = JSON.parse(atob(apiToken.split('.')[1])).exp;
    }

    if (tokenType === 1 && !userToken) {
      return null;
    }

    if (tokenExpires < Date.now() / 1000) {
      if (!tokenRequested) {
        tokenRequested = true;
        await fetch(requestUrl + 'token', {
          method: 'POST',
          body: JSON.stringify({ username: process.env.REACT_APP_API_USERNAME, password: process.env.REACT_APP_API_PASSWORD }),
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        }).then((res) => res.json()).then((data) => {
          apiToken = data;
          sessionStorage.setItem('apiToken', data.token);
        });
      }
      await new Promise(r => setTimeout(r, 100));
      return await Api(query, postParams, tokenType, noCache);
    }

    const options = {
      method: postParams ? 'POST' : 'GET', body: postParams ? JSON.stringify(postParams) : null, headers: {}
    };

    options.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (!tokenType || (tokenType === 2 && !userToken) ? apiToken : userToken)
    };

    let queryCode = md5(JSON.stringify({ query: query, postParams: postParams }));

    if (typeof runningQueries[queryCode] != 'undefined') {
      return runningQueries[queryCode];
    } else if (responses[queryCode] && !noCache) {
      return responses[queryCode];
    }


    runningQueries[queryCode] = fetch(requestUrl + (query ? query : ''), options).then(async (res) => {
      if (res.status === 401) {
        delete runningQueries[queryCode];
        sessionStorage.setItem('apiToken', 'undefined');
        sessionStorage.setItem('userToken', 'undefined');
        return Api(query, postParams, tokenType, noCache);
      }
      return res.json();
    }).then((data) => {
      tokenRequested = false;
      if (!postParams && !noCache) {
        responses[queryCode] = data;
      }
      delete runningQueries[queryCode];
      return data;
    });

    return await runningQueries[queryCode];
  }
  return null;
};
export default Api;